.divLoader {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fe;
  }
  
.pulsatingLogo {
    font-size: 50px;
    animation: pulse 0.8s infinite ease-out;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    15% {
        transform: scale(0.85);
    }
    50% {
        transform: scale(0.7);
    }
    70% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1); /* seeting back to initial size */
    }
}